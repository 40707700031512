<template>
  <div class="container_media picture-wrap" ref="container_media_ref">
    <div class="wf-item" v-for="(item, idx) in list" :key="idx" @click="toDetail(item)">
      <img :src="item.watermark_file || item.picture_url || item.watermark_url" alt="" class="wf-img"
        :class="safariClass ? 'safariClass' : ''" />
      <p class="bottom-name"><span>{{ item.name }}</span></p>
      <ul class="operation_icons">
        <li class="operation-item" @click.stop="handleShare('', item.picture_id || item.object_id, 5, item.name)">
          <img src="~@/assets/image/centerVideo/share.png" alt="">
        </li>
        <li class="operation-item" @click.stop="handleSoundCollect(item)">
          <img
            :src="`${item.is_collect === 0 ? require('@/assets/image/centerVideo/collect.png') : require('@/assets/svg/operation/collected.svg')}`"
            alt="">
        </li>
        <li class="operation-item" @click.stop="handleDownload({ ...item, type: 5 })">
          <img src="~@/assets/image/centerVideo/down.png" alt="">
        </li>
      </ul>
    </div>
    <div class="after" v-if="list.length > 0 && !fetchLoading" ref="afterRef">
      <div class="box" v-if="!endPage" @click="nextPage">
        <div class="img">
          <img src="~@/assets/image/page-right.png" alt="">
          <img src="~@/assets/image/page-more.png" alt="">
        </div>
      </div>
    </div>
    <el-empty style="margin: 0 auto;" v-if="list.length === 0" />
  </div>
</template>

<script>
import Buttons from '@/layout/mixins/Buttons'
import { getToken } from '@/utils/author'
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    endPage: {
      Boolean,
      default: false
    },
    fetchLoading: {
      Boolean,
      default: false
    },
    // 页面来源
    target: {
      type: String,
      default: ''
    }
  },
  mixins: [Buttons],
  data() {
    return {
      safariClass: false
    }
  },
  mounted() {
    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      console.log('~~~~~~~~~~Safari')
      this.safariClass = true
    }
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        path: '/picture/detail',
        query: {
          picture_id: item.object_id || item.picture_id,
          time: new Date().getTime()
        }
      })
      // window.open(`/picture/detail?picture_id=${item.object_id || item.picture_id}&backUrl=${window.location.href}`)
    },
    nextPage() {
      this.$emit('nextPage')
    },
    async handleSoundCollect(item) {
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      item.is_collect = item.is_collect === 0 ? 1 : 0
      await this.handleCollect(item.object_id, 5, {
        ...item,
        type: 5,
        labels: item.name
      }, false)
      // 收藏页面取消收藏后需重新拉取列表数据
      if (this.target === 'collect') {
        this.$emit('againGetList', 5)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.picture-wrap {
  margin: 40px auto 70px !important;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  flex-direction: row;
  -webkit-box-lines: multiple;
  flex-wrap: wrap;

  .after {
    -webkit-box-flex: 99999;
    -webkit-flex-grow: 99999;
    flex-grow: 99999;
    height: 240px;

    .box {
      width: calc(100% - 16px);
      height: 100%;
      background-image: url('~@/assets/image/more-bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      @include center-middle-flex;
      cursor: pointer;

      .img {
        display: -webkit-box;
        /* Safari, old iOS version */
        display: flex;
        align-items: center;
        height: 60px;

        img {
          @include size(auto, 100%);

          &:last-child {
            margin-left: 20px;
          }
        }
      }
    }
  }

  .wf-item {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    margin: 0px 16px 32px 0px;
    position: relative;
    overflow: hidden;
    height: 240px;
    cursor: pointer;
    @include center-middle-flex;
    background: #F5F5F7;

    &:hover {
      .wf-img {
        transform: scale(1.2);
      }

      .bottom-name {
        bottom: 0;
      }

      .operation_icons {
        right: 10px;
      }
    }

    .wf-img {
      transition: transform 0.3s ease-in-out;
      background-size: cover;
      background-position: center;
      @include size(100%, 100%);
    }

    .safariClass {
      width: auto
    }

    .bottom-name {
      @include size(100%, 40px);
      line-height: 40px;
      position: absolute;
      bottom: -40px;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #333333 100%);
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      padding-left: 12px;
      box-sizing: border-box;
      transition: bottom 0.3s ease-in-out;

      span {
        display: block;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .operation_icons {
      position: absolute;
      right: -42px;
      top: 10px;
      transition: right 0.3s ease-in-out;

      .operation-item {
        @include square(32px);
        @include center-middle-flex;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 4px;
        backdrop-filter: blur(1px);
        margin-bottom: 8px;

        img {
          @include square(18px);
          cursor: pointer;
        }
      }
    }
  }
}
</style>